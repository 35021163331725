import React, { ReactNode, useEffect, useState } from "react";
import { AccountInfo, Configuration, IPublicClientApplication, LogLevel, PublicClientApplication, SilentRequest } from "@azure/msal-browser";
import { ConfigContext } from "./ConfigContext";
import { AzureConfig, HostConfig, getHostConfigAsync } from "../../utils/CommonUtils/HostConfigActions";
import { FunctionalResult } from "@piranacon/lexxic-shared";

export const ConfigProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [msalConfig, setMsalConfig] = React.useState<Configuration | null>(null);
  const [b2cPolicies, setB2CPolicies] = React.useState<any | null>(null);
  const [initialisationComplete, setInitialisationComplete] = useState<boolean>(false);

  useEffect(() => {
    if(!initialisationComplete){
      setInitialisationComplete(true);

      getHostConfigAsync().then((value) => {
        if (value.isFailure) {
          return;
        }

        setB2CPolicies(createB2CPolicies(value.result!.azure));

        createMsalConfig(value.result!.azure).then((msalConfig) => {
          if (msalConfig) { // Change 'config' to 'msalConfig'
            setMsalConfig(msalConfig);
          }
        });
      })
    }
  })

  function createB2CPolicies(config: AzureConfig) {
    const b2cPolicies = {
      names: {
        signIn: 'B2C_1_Lexxic_Portal_Signin'
      },
      authorities: {
        signIn: {
          authority: config.signInAuthority,
        },
        signUp: {
          authority: config.signUpAuthority,
        }
      },
      authorityDomain: config.authorityDomain,
    };
    
    return b2cPolicies;
  }

  async function createMsalConfig(config : AzureConfig) : Promise<Configuration | null> {
    if (config) {
      const b2cPolicies = {
        names: {
          signIn: 'B2C_1_Lexxic_Portal_Signin'
        },
        authorities: {
          signIn: {
            authority: config.signInAuthority,
          },
          signUp: {
            authority: config.signUpAuthority,
          }
        },
        authorityDomain: config.authorityDomain,
      };

      const msalConfig = {
        auth: {
          clientId: config.clientId,
          authority: b2cPolicies.authorities.signIn.authority,
          knownAuthorities: [b2cPolicies.authorityDomain],
          redirectUri: '/passback',
          postLogoutRedirectUri: '/',
          navigateToLoginRequestUrl: false,
        },
        cache: {
          cacheLocation: config.cacheLocation,
          storeAuthStateInCookie: false,
        },
        system: {
          loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
              if (!containsPii) {
                switch (level) {
                  case LogLevel.Error:
                    console.error(message);
                    break;
                  case LogLevel.Info:
                    console.info(message);
                    break;
                  case LogLevel.Verbose:
                    console.debug(message);
                    break;
                  case LogLevel.Warning:
                    console.warn(message);
                    break;
                  default:
                    break;
                }
              }
            },
          },
        },
      };

      return msalConfig;
    }

    return null;
  }

  return (
    <ConfigContext.Provider value={{ msalConfig, initialisationComplete, updateConfig: setMsalConfig, b2cPolicies }}>
      {children}
    </ConfigContext.Provider>
  )
}

export const silentRequest = (currentAccount: AccountInfo): SilentRequest => {
  return {
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    account: currentAccount,
    forceRefresh: true,
    refreshTokenExpirationOffsetSeconds: 7200
  };
};