// src/layouts/RootLayout/RootLayout.tsx
import React from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import {
  CgOrganisation,
  CgBookmark,
  CgDollar,
  CgFileDocument,
} from 'react-icons/cg';

import { FaUsers } from 'react-icons/fa6';
import { useMsal } from '@azure/msal-react';
import {
  SideNav,
  SideNavButton,
  Breadcrumbs,
  BreadcrumbsLinks,
  UserClaimsContext,
  LexxicFooter,
} from '@piranacon/lexxic-shared';
import image from '../../lexxic.png';
import './Root.css';
import messages from './locales/en.json';

const RootLayout: React.FC = () => {
  const { userClaims } = React.useContext(UserClaimsContext);
  const { instance } = useMsal();
  const navigate = useNavigate();
  const location = useLocation();
  const handleLogout = () => {
    instance.logout();
  };

  const [buttons, setButtons] = React.useState<SideNavButton[]>([]);
  const [breadcrumb, setBreadcrumb] = React.useState<BreadcrumbsLinks[]>([]);

  React.useEffect(() => {
    var items = location.pathname.split('/');
    var list: BreadcrumbsLinks[] = [
      {
        label: 'Home',
        link: '/',
      },
    ];
    if (items.length > 1 && items[1] !== '') {
      list.push({ label: 'Me', link: '/me' });
    }
    setBreadcrumb(list);
  }, [location]);

  React.useEffect(() => {
    if (userClaims?.user?.role === 5) {
      setButtons([
        {
          id: 1,
          label: messages.Labels.EditDetails,
          icon: FaUsers,
          link: '/me/details',
        },
      ]);
    }
  }, []);
  return (
    <div className="layout-container">
      <div className="nav">
        <SideNav
          image={image}
          signOutClick={handleLogout}
          buttons={buttons}
          onClick={(path) => navigate(path)}
        />
      </div>
      <div className="main-content">
        <div className="breadcrumb">
          <Breadcrumbs
            breadcrumbLinks={breadcrumb}
            onClick={(to) => {
              navigate(to);
            }}
          />
        </div>
        <div className="body">
          <Outlet />
        </div>
      </div>
      <div className="footer">
        <LexxicFooter
          address={messages.Labels.Address}
          title={`${messages.Labels.LoggedIn} ${userClaims?.user?.name}, ${
            messages.Labels.Role
          } ${messages.Labels.Roles[userClaims?.user?.role || 0]}.`}
          image={image}
        />
      </div>
    </div>
  );
};

export default RootLayout;
