import React, { useContext } from 'react';
import { SystemUserClaims } from '../../objects/UserClaims.types';
import { UserClaimsContext } from '@piranacon/lexxic-shared';

const UserClaimsConsumer: React.FC<{ children: (userClaims: SystemUserClaims | null) => React.ReactNode }> = ({ children }) => {
  const { userClaims } = useContext(UserClaimsContext);
  return <>{children(userClaims)}</>;
};

export default UserClaimsConsumer;
