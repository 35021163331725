import settings from '../../Settings/services.json';
import {
  confirmAction,
  fetchAction,
  fetchActionNoAuthWithBody,
  fetchActionWithBody,
  UpdateAction,
  FunctionalResult,
  IUserClaims,
  CreateAction,
} from '@piranacon/lexxic-shared';
import policyDetails from '../../Settings/currentPolicy.json';
import { getHostConfigAsync } from '../CommonUtils/HostConfigActions';
import CustomerDetails from '../../objects/APIModels/CustomerDetails.types';
import ExternalUser from '../../objects/APIModels/ExternalUser.types';
import { RequestHeaders } from '../../objects/common.types';

namespace userActions {
  /**
   * Save the user changes
   * @param user The account details to save
   * @returns The result of the action
   */
  export const saveUserDetailsAsync = async (
    header: FunctionalResult<RequestHeaders>,
    user: CustomerDetails.Customer,
    id?: string
  ): Promise<FunctionalResult<string>> => {
    if (header.isFailure) {
      return FunctionalResult.Error('Failed to get headers');
    }
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    if (id !== undefined) {
      return UpdateAction({
        hostPath: host.result!.path,
        apiPath: `${settings.userDetails.update.path}`,
        userId: header.result!.id,
        accessToken: header.result!.accessToken,
        formData: user,
      });
    }
    return CreateAction({
      hostPath: host.result!.path,
      apiPath: `${settings.userDetails.set.path}`,
      userId: header.result!.id,
      accessToken: header.result!.accessToken,
      formData: user,
    });
  };

  export const getDefaultDetails = (id: string): CustomerDetails.Customer => {
    return {
      employmentContactName: '',
      employmentHistory: '',
      lengthOfEmployment: '',
      preferredName: '',
      roleAndResponsibilities: '',
      userAccountId: id,
      customerInEducation: getCustomerInEducationDetails(),
      employmentType: undefined,
    };
  };

  export const getCustomerInEducationDetails =
    (): CustomerDetails.EducationDetails => {
      return {
        courseName: '',
        lengthOfCourse: '',
        universityCollegeName: '',
        yearOfStudy: '',
      };
    };

  /**
   * Activates an external user account, linking the user to their identity provider identifier
   * @param inviteCredentials The invite credentials
   * @returns A result indicating the success of the operation
   */
  export const activateUserAccountAsync = async (
    linkExternalAccountToIdentityProviderRecord: ExternalUser.LinkExternalAccountToIdentityProviderRecord,
    accessToken: string
  ): Promise<FunctionalResult<IUserClaims>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    return fetchActionWithBody(
      {
        hostPath: host!.result!.path,
        apiPath: `${settings.activateUser.set.path}`,
        userId:
          linkExternalAccountToIdentityProviderRecord.identityProviderIdentifier,
        accessToken: accessToken,
      },
      linkExternalAccountToIdentityProviderRecord
    );
  };

  /**
   * Validates an invite token prior to allowing the user to sign up
   * @param inviteCredentials The validation credentials
   * @returns A result indicating the success of the operation
   */
  export const validateInviteTokenAsync = async (
    validateInviteTokenRecord: ExternalUser.ValidateInviteTokenRecord
  ): Promise<FunctionalResult<boolean>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return fetchActionNoAuthWithBody(
      {
        hostPath: host!.result!.path,
        apiPath: `${settings.validateInviteToken.set.path}`,
        method: 'POST',
      },
      validateInviteTokenRecord
    );
  };

  /**
   * Retrieves user claims
   * @param identityProviderId The identity provider identifier
   * @param emailAddress The email address
   * @returns A result indicating the success of the operation containing the user claims
   */
  export const retrieveUserClaimsAsync = async (
    identityProviderId: string,
    emailAddress: string,
    accessToken: string
  ): Promise<FunctionalResult<IUserClaims>> => {
    let encodedEmail = encodeURIComponent(emailAddress);
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.claims.retrieveClaims.path}?${settings.claims.retrieveClaims.queryIdentity}=${identityProviderId}&${settings.claims.retrieveClaims.queryEmail}=${encodedEmail}`,
      accessToken: accessToken,
    });
  };

  /**
   * Saves the consent back to the database
   * @param {string} ip The Ip address
   * @param {FunctionalResult<RequestHeaders>} usr - The header information
   * @returns If the action was successful.
   */
  export const saveConsentAsync = async (
    header: FunctionalResult<RequestHeaders>,
    ip: string
  ) => {
    if (header.isFailure) {
      return FunctionalResult.Error('Failed to get headers');
    }
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    return confirmAction({
      hostPath: host!.result!.path,
      apiPath: settings.consent.path,
      formData: {
        userAccountId: header.result!.id,
        policyName: policyDetails.policyName,
        version: policyDetails.version,
        ipAddress: ip,
      },
      accessToken: header.result!.accessToken,
      userId: header.result!.id,
    });
  };

  /**
   * Gets the latest claims
   * @param {FunctionalResult<RequestHeaders>} header - The header information
   * @returns the result of the action
   */
  export const refreshClaimsAsync = async (
    header: FunctionalResult<RequestHeaders>
  ): Promise<FunctionalResult<IUserClaims>> => {
    const host = await getHostConfigAsync();
    if (header.isFailure) {
      return FunctionalResult.Error('Failed to get headers');
    }
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: settings.claims.Refresh.path,
      userId: header.result!.id,
      accessToken: header.result!.accessToken,
    });
  };

  /**
   * Determines if the user has agreed to the latest consent policy
   * @param userClaims  The user claims
   * @returns A flag indicating if the user has agreed to the policy
   */
  export const userHasAgreedToPolicy = (
    userClaims: IUserClaims | null
  ): boolean => {
    if (userClaims === null || !userClaims) {
      return false;
    }
    return userClaims?.consentRecords!.some(
      (c) => c.version === policyDetails.version
    );
  };

  export const getCustomerProfileAsync = async (
    header: FunctionalResult<RequestHeaders>
  ): Promise<FunctionalResult<CustomerDetails.Customer>> => {
    if (header.isFailure) {
      return FunctionalResult.Error('Failed to get headers');
    }
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.userDetails.get.path}/${header.result!.id}`,
      userId: header.result!.id,
      accessToken: header.result!.accessToken,
    });
  };
}

export default userActions;
