// src/pages/HomePage.tsx
import { UserClaimsContext } from '@piranacon/lexxic-shared';
import React, { useContext } from 'react';

const HomePage: React.FC = () => {
  const { userClaims } = useContext(UserClaimsContext);

  if (!userClaims?.user) {
    return null;
  }

  return userClaims?.user &&
    (
      <>
        <div>
          <p>This is the homepage content for {userClaims.user.name} with role {userClaims.user.role}.</p>
        </div>
      </>
    );
};

export default HomePage;
