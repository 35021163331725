import { createBrowserRouter } from 'react-router-dom';
import { Layouts, Pages } from './pages/PageImports';
import path from 'path';

const notAuthRoutes = [
  {
    path: '/',
    element: <Pages.Landing />
  },
  {
    path: '/passback',
    element: <Pages.Loading />,
  },
  {
    path: '/handle-invite',
    element: <Pages.InviteHandlerPage />,
  }
];

const authRoutes = [
  {
    path: '/passback',
    element: <Pages.Loading />,
  },
  {
    path: '/me',
    element: <Layouts.BaseLayout />,
    errorElement: <Pages.Error />,
    children: [
      {
        path: '/me/details',
        element: <Pages.EditDetailsPage />,
      },
      {
        path: '/me/consent',
        element: <Pages.ConsentView displayAgreement={false} />,
      },
    ],
  },
  {
    path: '/',
    element: <Layouts.BaseLayout />,
    errorElement: <Pages.Error />,
    children: [
      {
        index: true,
        element: <Pages.Home />,
      },
      {
        path: 'landing',
        element: <Pages.Landing />,
      },
      {
        path: 'handle-invite',
        element: <Pages.InviteHandlerPage />,
      }
    ],
  },
];

export const authRouter = createBrowserRouter(authRoutes);
export const notAuthRouter = createBrowserRouter(notAuthRoutes);
