import React from "react";
import { ConfigContextType } from "../../objects/ConfigContextType.types";
import { Configuration } from "@azure/msal-browser";

export const ConfigContext = React.createContext<ConfigContextType>({
  msalConfig: null,
  initialisationComplete : false,
  b2cPolicies : null,
  updateConfig: (updatedConfig: Configuration) => {}
});
