import React, { ReactNode, useEffect, useRef, useState } from 'react';
import usrActions from '../../utils/userUtils/UserActions';
import settings from '../../Settings/services.json';
import { EventType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { UserClaimsContext, useUserClaims } from '@piranacon/lexxic-shared';
import userActions from '../../utils/userUtils/UserActions';

export const UserClaimsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const claimsStorage = useRef<boolean>(false);
  const { instance: msalInstance } = useMsal();
  const {
    userClaims,
    initialisationComplete,
    loadClaimsAsync,
    removeClaims,
    saveClaims,
    setUnauthorised,
  } = useUserClaims(userActions.retrieveUserClaimsAsync);

  useEffect(() => {
    handleRedirectAsync();

    msalInstance.addEventCallback((event: any) => {
      if (event.eventType === EventType.LOGOUT_SUCCESS
        || event.eventType === EventType.LOGOUT_START) {
        removeClaims();
      }
    });

    let account = msalInstance.getActiveAccount();

    if (account === null) {
      return;
    }

    loadClaimsAsync(account);
  }, []);

  /**
   * Handles the redirect asynchronously and performs various checks and actions based on the token response.
   */
  const handleRedirectAsync = async () => {
    msalInstance
      .handleRedirectPromise()
      .then(async (tokenResponse) => {
        // If the tokenResponse === null, you are not coming back from an auth redirect.
        if (
          tokenResponse !== null &&
          !claimsStorage.current &&
          !userClaims.user
        ) {
          claimsStorage.current = true;
          let account = msalInstance.getActiveAccount()!;

          if (
            account.idTokenClaims === undefined ||
            account.idTokenClaims === null
          ) {
            setUnauthorised();
          }

          let inviteToken = account.idTokenClaims![
            settings.claimKeys.inviteToken
          ] as string | null | undefined;
          let isNewUser = account.idTokenClaims![settings.claimKeys.newUser] as
            | string
            | null
            | undefined;

          if (inviteToken === null || inviteToken === undefined) {
            return;
          }

          let postObject = {
            identityProviderIdentifier: account.localAccountId,
            inviteToken: inviteToken,
            userAccountEmailAddress: account.username,
          };

          if (isNewUser === 'true') {
            usrActions
              .activateUserAccountAsync(
                postObject,
                msalInstance.getActiveAccount()!.idToken!
              )
              .then((activationResult) => {
                if (activationResult.isSuccess && activationResult.result) {
                  saveClaims(activationResult.result!);
                  return;
                }
              })
              .catch((error: any) => {
                console.log(error);
              });

            removeClaims();
            return;
          }

          loadClaimsAsync(account);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <UserClaimsContext.Provider
      value={{
        userClaims,
        initialisationComplete,
        updateUserClaims: saveClaims,
      }}
    >
      {children}
    </UserClaimsContext.Provider>
  );
};
