import settings from '../../Settings/services.json';
import {
  fetchAction,
  fetchActionNoAuth,
  FunctionalResult,
  KeyValuePair,
  SystemUserClaims,
} from '@piranacon/lexxic-shared';
import { getHostConfigAsync } from './HostConfigActions';
import { RequestHeaders } from '../../objects/common.types';
import { IPublicClientApplication } from '@azure/msal-browser';
/**
 *  Return the current date, this is a hook for testing purposes.
 * @returns Current date.
 */
export const getDate = (): Date => {
  return new Date();
};

/**
 * A call to get the users IP address
 * @returns {FunctionalResult<string>} With the users Ip Address
 */
export const fetchIPInfo = async (): Promise<FunctionalResult<string>> => {
  try {
    const response = await fetch('https://ipinfo.io/json');
    const data = await response.json();
    return FunctionalResult.Success(data.ip);
  } catch (error) {
    return FunctionalResult.Error('Error fetching client IP');
  }
};

namespace Enumerations {
  /**
   * Gets all the disability's in the system
   * @returns FunctionalResult<KVP[]>
   */
  export const getDisabilitiesAsync = async (): Promise<
    FunctionalResult<KeyValuePair<number>[]>
  > => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return fetchActionNoAuth({
      hostPath: host!.result!.path,
      apiPath: settings.enumerationPaths.disabilities.path,
    });
  };

  /**
   * Gets all the Employment Types in the system
   * @returns FunctionalResult<KVP[]>
   */
  export const getEmploymentTypeAsync = async (): Promise<
    FunctionalResult<KeyValuePair<number>[]>
  > => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return fetchActionNoAuth({
      hostPath: host!.result!.path,
      apiPath: settings.enumerationPaths.employmentType.path,
    });
  };

  /**
   * Gets all the ethnicity's in the system
   * @returns FunctionalResult<KVP[]>
   */
  export const getEthnicityAsync = async (): Promise<
    FunctionalResult<KeyValuePair<number>[]>
  > => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return fetchActionNoAuth({
      hostPath: host!.result!.path,
      apiPath: settings.enumerationPaths.ethnicity.path,
    });
  };

  /**
   * Gets all the pronouns's in the system
   * @returns FunctionalResult<KVP[]>
   */
  export const getGenderAsync = async (): Promise<
    FunctionalResult<KeyValuePair<number>[]>
  > => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return fetchActionNoAuth({
      hostPath: host!.result!.path,
      apiPath: settings.enumerationPaths.genders.path,
    });
  };

  /**
   * Gets all the pronouns's in the system
   * @returns FunctionalResult<KVP[]>
   */
  export const getPronounsAsync = async (): Promise<
    FunctionalResult<KeyValuePair<number>[]>
  > => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return fetchActionNoAuth({
      hostPath: host!.result!.path,
      apiPath: settings.enumerationPaths.pronouns.path,
    });
  };

  /**
   * Gets all the roles's in the system
   * @returns FunctionalResult<KVP[]>
   */
  export const getRolesAsync = async (): Promise<
    FunctionalResult<KeyValuePair<number>[]>
  > => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return fetchActionNoAuth({
      hostPath: host!.result!.path,
      apiPath: settings.enumerationPaths.externalUserRoles.path,
    });
  };

  /**
   * Gets all the User Statuses in the system
   * @returns FunctionalResult<KVP[]>
   */
  export const getUserStatuses = async (): Promise<
    FunctionalResult<KeyValuePair<number>[]>
  > => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return fetchActionNoAuth({
      hostPath: host!.result!.path,
      apiPath: settings.enumerationPaths.userStatuses.path,
    });
  };
}

export const getHeaderInfo = (
  usr?: SystemUserClaims | null,
  instance?: IPublicClientApplication
): FunctionalResult<RequestHeaders> => {
  if (
    instance === undefined ||
    !instance ||
    !instance.getActiveAccount ||
    !instance.getActiveAccount()!.idToken
  ) {
    return FunctionalResult.Error('Instance is not valid');
  }

  if (usr === undefined || usr === null || !usr || !usr.user || !usr.user.userAccountId) {
    return FunctionalResult.Error('Usr is not valid');
  }

  return FunctionalResult.Success({
    id: usr.user.userAccountId!,
    accessToken: instance.getActiveAccount()!.idToken!,
  });
};

export default Enumerations;
