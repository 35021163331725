import RootLayout from '../layouts/RootLayout/RootLayout';
import ErrorPage from './error-page/error-page';

import Consent from './Consent/Consent';

import LoadingPage from './Loading/Loading';
import LandingPage from './LandingPage/LandingPage';
import HomePage from './HomePage';
import EditDetails from './EditDetails/EditDetails';
import InviteHandler from '../components/Authentication/InviteHandler';

export namespace Pages {
  export const Error = ErrorPage;
  export const Landing = LandingPage;
  export const Loading = LoadingPage;
  export const InviteHandlerPage = InviteHandler;

  
  export const Home = HomePage;
  export const ConsentView = Consent;

  export const EditDetailsPage = EditDetails;
}

export namespace Layouts {
  export const BaseLayout = RootLayout;
}
