import React from 'react';
import { useIntl } from 'react-intl';

const LoadingDiv: React.FC = () => {
  const intl = useIntl();
  const messages = require(`./locales/${intl.locale}.json`);
  return (
    <div className="Center-Placer">
      <div className="Center-Div">
        <div></div>
        <div style={{ backgroundColor: 'white' }}>
          <div className="header">{messages.header}</div>
          <div>{messages.loading}</div>
        </div>
      </div>
    </div>
  );
};

export default LoadingDiv;
