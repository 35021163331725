import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import usrActions from '../../utils/userUtils/UserActions';
import { ConfigContext } from './ConfigContext';

const InviteHandler: React.FC = () => {
    const location = useLocation();
    const { accounts } = useMsal();
    const search = location.search;
    const token = new URLSearchParams(search).get('inviteToken');
    const email = new URLSearchParams(search).get('emailAddress');
    const [isInvalid, setIsInvalid] = useState(false);
    const { b2cPolicies } = useContext(ConfigContext);

    const request = {
        scopes: [],
        authority: b2cPolicies.authorities.signUp.authority,
        redirectUri: "/",
        extraQueryParameters: {
            "invite_token": token ? token : "",
            "email_address": email ? encodeURIComponent(email) : ""
        }
    }
    const { login, error } = useMsalAuthentication(InteractionType.Silent, request);

    useEffect(() => {
        if (token && email && accounts.length === 0) {
            let validationRecord = {
                inviteToken: token,
                userAccountEmailAddress: email
            }

            usrActions.validateInviteTokenAsync(validationRecord)
                .then((result) => {
                    if (result.isSuccess && result.result) {
                        login(InteractionType.Redirect, request);
                        return;
                    }

                    setIsInvalid(true);
                    return;
                })
                .catch((error) => {
                    console.error("Error occurred during validation:", error);
                    setIsInvalid(true);
                    return;
                });
        }

        setIsInvalid(true);
    }, [error])


    return (
        <>
            <div>
                {(isInvalid)
                    ? (
                        <div id="error-page">
                            <h1>Oops!</h1>
                            <p>Sorry, an unexpected error has occurred.</p>
                            <p>
                                <i>Invalid invite link</i>
                            </p>
                        </div>
                    )
                    : <p> Processing invite...</p>}
            </div>
        </>
    );
}

export default InviteHandler;
